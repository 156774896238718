import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HelpComponent} from '../help/help.component';
import {AbaoutComponent} from '../about/abaout.component';
import {DisplayComponent} from '../display/display.component';


const routes: Routes = [
  { path: '', component: DisplayComponent},
  { path: 'help', component: HelpComponent},
  { path: 'about', component: AbaoutComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
