HTML
<header>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-secondary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">QR-Folder</a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="fixedNavbarExample.toggle()"
        aria-controls="fixedNavbarExample"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars mr-2"></i>
      </button>
      <div
        mdbCollapse
        #fixedNavbarExample="mdbCollapse"
        class="collapse navbar-collapse"
        id="fixedNavbarExample"
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <a (click)="fixedNavbarExample.hide()" class="nav-link" aria-current="page" routerLink="/help">Hilfe</a>
          </li>
          <li class="nav-item">
            <a (click)="fixedNavbarExample.hide()" class="nav-link" routerLink="/about" >About</a>
          </li>
          <li class="nav-item">
            <a (click)="fixedNavbarExample.hide()" class="nav-link" [routerLink]="['/']" [queryParams]="{qr: '47114711'}">QR-Code lesen</a>
          </li>
          <li class="nav-item">
            <a (click)="fixedNavbarExample.hide()" class="nav-link" routerLink="/">Ohne QR-Code</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Navbar -->

</header>


<router-outlet></router-outlet>

